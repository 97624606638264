import useFetch from "../useFetch"

export default async () => {
  const fetchUrl = process.env.REACT_APP_PLC_SERVICES_DONATIONS_URL + `/braintree/token`

  const [response, error] = await useFetch(fetchUrl)

  if ( response && response.ok ) return [response.body, error]
  else return [null, "There was an issue loading PayPal. Try refreshing the page."]

}
