import React from "react"
import { Spinner } from "react-bootstrap"

export default () => {
  return (
    <div className="pldf-processing" id="pldf-view-processing" style={{ textAlign: 'center', minHeight: '35vh' }}>
      <p style={{ margin: '0 0 1rem 0' }}>Processing...</p>
      <Spinner animation="border" role="status" >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  )
}
