import React, { useContext, useEffect, useState } from "react"
import { Alert, Button, InputGroup, Form, FormControl } from "react-bootstrap"

import Context from "../../context"
import { FrequencyTabs } from "../../components"

import stringToInt from "../../utilities/stringToInt"
import formatCurrency from "../../utilities/formatCurrency"
import dollarsToCents from "../../utilities/dollarsToCents"

export default function Amount() {

  const context = useContext(Context)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const minimumAmount = 5

  const handleAmountChange = ({ target }) => {
    const newValue = stringToInt(target.value)
    context.setAmount(newValue)
    if ( newValue >= minimumAmount ) {
      setFeedbackMessage()
    }
  }

  const handleButtonClick = () => {
    if ( context.amount >= minimumAmount ) {
      setFeedbackMessage()
      context.nextStep()
      context.setIsModalOpen( true )
    }
    else {
      setFeedbackMessage(`Amount must be $${minimumAmount} or greater.`)
    }
  }

  useEffect(() => {
		if (!context.paymentRequest) return
		if (context.amountTotal > 0 && context.paymentRequest) {
			context.paymentRequest.update({
				total: {
					label:
						context.frequency === "monthly"
							? "Monthly donation"
							: "One time donation",
					amount: dollarsToCents(context.amountTotal),
				},
			})
		}
	}, [context.amountTotal, context.paymentRequest, context.frequency])

  return <div className="pldf-view-amount">

    <FrequencyTabs />
    <div className="pldf-amount-content">

      <Form.Group>
      <InputGroup size="lg" className="pldf-input-group-amount">
        <InputGroup.Prepend>
          <InputGroup.Text>Give</InputGroup.Text>
        </InputGroup.Prepend>

        <span className="pldf-form-control-amount-wrapper">
          <FormControl
            pattern="[0-9]*"
            className="pldf-form-control-amount"
            value={ formatCurrency(context.amount) }
            placeholder="$"
            onChange={handleAmountChange}
          />
          <span className="pldf-currency-label" aria-label="United States Dollar">
            USD
          </span>
        </span>

        { context.frequency === "monthly" && (
          <InputGroup.Append>
            <InputGroup.Text>every month</InputGroup.Text>
          </InputGroup.Append>
        ) }
      </InputGroup>
      </Form.Group>

      <span className="pldf-note-label">
        Your monthly donation provides us with the flexibility to respond to emergencies.
      </span>
      { feedbackMessage && <Alert variant="warning">{ feedbackMessage }</Alert> }

      <Form.Group className="pdlf-form-group-cover-fees" controlId="cover-fees">
        <Form.Check
          type="checkbox"
          label={`Please increase my donation by ${context.feeIncreasePercent}% to cover payment processing fees.`}
          onChange={ ()=> context.setHasCoveredFees( ! context.hasCoveredFees ) }
          checked={ context.hasCoveredFees }
        />
      </Form.Group>

      <Button
        block
        type="submit"
        disabled={ !context.amountTotal || context.amountTotal < 1 }
        onClick={ handleButtonClick }
      >
        Give now
      </Button>

      <Button
        href="/donate#ways-to-give"
        variant="link"
        className="pldf-btn-other-ways-to-give"
        block>Other ways to give</Button>

    </div>

  </div>

}
