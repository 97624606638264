export default async function getAuth0Token() {
  const verifiedResponse = await fetch("/wp-json/plc/v1/auth0-token", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  const jsonString = await verifiedResponse.json()
  const parsed = JSON.parse(jsonString)
  return parsed
} 