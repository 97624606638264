import { parseFullName } from "parse-full-name"
import React, { useContext, useEffect } from "react"
import {
	injectStripe,
	PaymentRequestButtonElement,
} from "react-stripe-elements"

import Context from "../../context"
import dollarsToCents from "../../utilities/dollarsToCents"

export default injectStripe(_StripePaymentRequestButton)

function _StripePaymentRequestButton({ stripe }) {
	const context = useContext(Context)

	useEffect(() => {
		if (!stripe) return 
		if (!context.amountTotal || context.amountTotal < 1) return 
		if (context.paymentRequest) return // don't make another request if already exists

		const request = stripe.paymentRequest({
			country: "US",
			currency: "usd",
			total: {
				label:
					context.frequency === "monthly"
						? "Monthly donation"
						: "One time donation", 
				amount: dollarsToCents(context.amountTotal) || 0,
			},
			requestPayerName: true,
			requestPayerEmail: true,
			requestPayerPhone: true,
			requestShipping: true,
			shippingOptions: [
				{
					id: "donation-shipping",
					label: "Ground",
					detail: "Used to send tax statement",
					amount: 0,
				},
			],
		})

		request.on("token", ({ token, complete, ...data }) => {
			if (token && token.id) {
				context.setPaymentMethod("Payment Request")

				data?.payerEmail && context.setEmail(data.payerEmail)
				if (data?.payerName) {
					const names = parseFullName(data.payerName)
					names?.first && context.setFirstName(names.first)
					names?.middle && context.setMiddleName(names.middle)
					names?.last && context.setLastName(names.last)
					names?.suffix && context.setSuffix(names.suffix)
				}
				data?.payerPhone && context.setPhone(data.payerPhone)

				const shipping = data?.shippingAddress

				shipping?.addressLine?.[0] && context.setAddress1(shipping.addressLine[0])
				shipping?.addressLine?.[1] && context.setAddress2(shipping.addressLine[1])
				shipping?.city && context.setCity(shipping.city)
				shipping?.country && context.setCountry(shipping.country)
				shipping?.postalCode && context.setPostal(shipping.postalCode)
				shipping?.region && context.setRegion(shipping.region)

				context.setStripeToken(token)
				context.setPaypalNonce()

				complete("success")
			}
		})

		// Check the availability of the Payment Request API.
		request.canMakePayment().then((result) => {
			if (result) {
				context.setPaymentRequest(request)
			}
		})
		// eslint-disable-next-line
	}, [stripe, context.amountTotal]) // not passing all of context in array because we're not accessing state, only setState for `context`

	if (!context.paymentRequest) return null

	return (
		<PaymentRequestButtonElement
			paymentRequest={context.paymentRequest}
			className="PaymentRequestButton"
			style={{
				paymentRequestButton: {
					type: "donate",
					theme: "dark",
					height: "64px",
				},
			}}
		/>
	)
}
