const loadStripeScript = ({ setStripe }) => {
	if (!!window.Stripe) {
		setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
	} else {
		const stripeScriptId = "stripe-v3-script"
		const stripeScript = document?.getElementById(stripeScriptId)
		if (!!stripeScript) {
			let timesChecked = 0
			let stripeScriptInterval = setInterval(() => {
				timesChecked++
				if (timesChecked >= 35) {
					console.error("Could not load Stripe")
					clearInterval(stripeScriptInterval) // 35 x 300ms ~= 10s
				}
				if (!!window.Stripe) {
					setStripe(
						window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
					)
					clearInterval(stripeScriptInterval)
				}
			}, 300)
			return
		}
		const stripeJs = document.createElement("script")
		stripeJs.src = "https://js.stripe.com/v3/"
		stripeJs.async = true
		stripeJs.id = stripeScriptId
		stripeJs.onload = () => {
			setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
		}
		document.body && document.body.appendChild(stripeJs)
	}
}

export default loadStripeScript
