import React, { useContext } from "react"
import { Modal } from "react-bootstrap"
import ReCAPTCHA from "react-google-recaptcha"

import Context from "../../context"
import {
  Amount,
  YourInformation,
  Complete,
  PaymentMethod,
  OtherMethods,
  Review,
  Processing,
  WaysToGive,
} from "../../views"

export default () => {
  const context = useContext( Context )

  return (
<>
  <Modal
    show={ context.isModalOpen }
    onHide={()=> context.close()}
    size="lg"
    centered
    className={`pldf-current-view-${context.view}`}
  >
  <Modal.Body>
    <div className="pldf-row pldf-row-views">
    {/* {context.modalImageUrl && (
    <div className="pldf-col-md-6 pldf-col-image">

      <img src={ context.modalImageUrl } alt={ context.modalImageAlt } />

    </div>
    )} */}
    <div className="pldf-col pldf-col-view">

      { context.view === "enter-amount" && <Amount /> }
      { context.view === "your-information" && <YourInformation /> }
      { context.view === "complete" && <Complete /> }
      { context.view === "payment-method" && <PaymentMethod /> }
      { context.view === "other-methods" && <OtherMethods /> }
      { context.view === "review" && <Review /> }
      { context.view === "processing" && <Processing /> }
      { context.view === "ways-to-give" && <WaysToGive /> }

      <ReCAPTCHA
        ref={context.recaptchaRef}
        size="invisible"
        sitekey="6LduQOIZAAAAAIW3g1x8-ZFCptl0p-HZKEGxQJMZ"
      />

    </div>
    </div>
  </Modal.Body>
  </Modal>
</>
  )

}
