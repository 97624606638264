/*
 * useFetch
 * This function returns an array of [response, error]
 * [response] will be the response object from the endpoint, including any forwarded errors that are valid responses
 * [error] wil be null unless the actual fetch fails, not if the response includes valid errors
 */

const useFetch = async (
  endpoint, 
  {
    method,
    body,
    headers,
  }
) => {
  let config = {
    mode: "cors",
    method,
    headers
  }
  let fetchUrl = endpoint

  if (method === "GET") {
    if (body) {
      const params = new URLSearchParams(body)
      fetchUrl = `${endpoint}/?${params.toString()}`
    }
  } else {
    config.body = JSON.stringify(body)
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...config.headers
    }
  }

  try {
    const response = await fetch(fetchUrl, config)
    const contentType = response.headers.get( 'Content-Type' )
    const body = contentType === 'application/json; charset=utf-8' ? await response.json() : await response.text()
    const success = {
      ok: response.ok,
      status: response.status,
      body,
    }
    return [success, null]
  } catch (error) {
    return [null, error]
  }
}

export default useFetch
