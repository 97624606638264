export const openDriftSidebar = () => {
	window?.drift &&
		window.drift.on("ready", (api) => {
			api.sidebar.open()
		})
}

export const closeDriftSidebar = () => {
	window?.drift &&
		window.drift.on("ready", (api) => {
			api.sidebar.close()
		})
}

export const toggleDriftSidebar = () => {
	window?.drift &&
		window.drift.on("ready", (api) => {
			api.sidebar.toggle()
		})
}
