/**
 * @param paymentload REQUIRED object
 *   @prop firstName REQUIRED string
 *   @prop lastName REQUIRED string
 *   @prop email REQUIRED string
 *   @prop phone REQUIRED string
 *   @prop ipAddress string
 *   @prop amount REQUIRED integer | cents
 *   @prop amountType string
 *   @prop stripeToken REQUIRED string
 *   @prop isMonthly REQUIRED boolean
 *   @prop feeIncreasePercent integer
 *   @prop hasCoveredFees boolean
 *   @prop campaignName REQUIRED string
 *   @prop utmSource string
 *   @prop utmMedium string
 *   @prop utmContent string
 *   @prop utmCampaign string
 *   @prop utmTerm string
 *   @prop formLayout string
 *   @prop formContext string
 *   @prop formSource string
 *
 * TODO: make required props function arguments, then pass the rest as an object
 */

/*
https://stripe.com/docs/error-codes

*/

import useFetch from "../useFetch"
import getAuth0Token from '../getAuth0Token'

export default async (paymentload) => {
  const fetchUrl = process.env.REACT_APP_PLC_SERVICES_DONATIONS_URL + `/payments/process-donation`

  const messages = {
    card_decline_rate_limit_exceeded: "You card has been declined too many times. Please try again in 24 hours.",
    card_declined: "Your card was declined. Please contact your card issuer or try another card.",
    expired_card: "Your card is expired. Please try another card.",
    incorrect_cvc: "Your card details are incorrect. Please double-check the information you've provided or try another card.",
    incorrect_number: "Your card details are incorrect. Please double-check the information you've provided or try another card.",
    missing_us_state: "Your address with PayPal is missing the US state so we can't receive your gift. Please add that in your PayPal account and come back here to try again.",
    no_card_data: "Please select a payment method.",
    requires_authentication: "Oh no! We don't currently support your card. Please consider donating with another method or contact us.",
    server_error: "Oh no! It looks like our payment processor is having issues. Please try again later or contact us. You can also try changing payment methods.",
    unknown_error: "Oh no! It looks like something went wrong. Please try again later or contact us.",
  }

  const { access_token } = await getAuth0Token()

  const [response] = await useFetch(
    fetchUrl, 
    {
      method: "POST", 
      body: paymentload,
      headers: {
        Authorization: `Bearer ${ access_token }`
      }
    }
  )
  console.log('create-donation response',response)

  let success = null
  let error = null

  // Successful
  if ( response.body.success ) {
    success = true
  }
  // Failed
  else {
    const errorCode = response.body.error?.code
    error = {
      code: errorCode || 'unknown',
      message: messages[errorCode] || messages['unknown_error'],
    }
    // Alert in Sentry if we aren't aware of the error case
    if ( ! errorCode || ! messages[errorCode] ) {
      console.error( 'Unknown error while processing donation. PM response body:', response )
    }
  }

  return [success, error]

}
