import React, { useState, useEffect, useCallback} from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/browser"
import { ThemeProvider } from "react-bootstrap"
import TagManager from "react-gtm-module"
import { StripeProvider, Elements } from "react-stripe-elements"

import { version } from "../package.json"
import "./styles/index.scss"
import { Provider } from "./context"
import { Launcher, Modal } from "./components"
import bootstrapClasses from "./utilities/bootstrapClasses.json"
import loadStripeScript from './utilities/loadStripeScript';


Sentry.init({
  dsn: "https://dba80b3fb6c44c60b219076913c2fcf1@o265848.ingest.sentry.io/5224668",
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: `donate-form@${version}`,
})
// console.log(`donate-form@${version}`)

if ( process.env.REACT_APP_GTM_ID ) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
    dataLayerName: process.env.REACT_APP_DATA_LAYER,
  })
}

const DonateForm = ({ htmlData }) => {
  const [stripe, setStripe] = useState(null)

  useEffect(() => {
    loadStripeScript({setStripe})
  }, [])

  return (

  <ThemeProvider prefixes={
    bootstrapClasses.reduce( ( object, className ) => {
      object[className] = `${process.env.REACT_APP_NAMESPACE}-${className}`
      return object
    }, {} )
  }>
  <StripeProvider stripe={stripe}>
  <Provider htmlData={ htmlData } isStripeLoaded={!!stripe}>
  <Elements>
    <>
    <Launcher />
    <Modal />
    </>
  </Elements>
  </Provider>
  </StripeProvider>
  </ThemeProvider>

  )}

Array.prototype.forEach.call(
  document.getElementsByClassName( process.env.REACT_APP_NAMESPACE ),
  form => { ReactDOM.render(
    <DonateForm htmlData={ form.dataset } />,
    form
  ) }
)
