import React from "react" // must be imported for JSX to work
import { Table } from "react-bootstrap"

export default [
  {
    name: "Stocks",
    content: (
      <>
        <p>
          Giving a stock donation is a simple and powerful way to show up for families long-term. By giving appreciated securities or mutual funds:
        </p>
        <ul>
          <li>You can eliminate federal capital gains taxes on the transfer.</li>
          <li>
            Your stock donation is tax-deductible based on the fair market value of the securities at the time of the transfer.
          </li>
        </ul>
        <p>
          <strong>For securities being held by a brokerage firm</strong>: tell your broker that you wish to donate the securities to Preemptive Love and share this{" "}
          <a href="https://preemptivelove.org/wp-content/uploads/2020/05/Account-Transfer_Form.pdf">
            accounts transfer form
          </a>{" "}
          with them along with our account number, 16089734.
        </p>
        <p>
          <strong>For mutual fund shares held directly in an account with the mutual fund group</strong>: Please use the
          same{" "}
          <a href="https://preemptivelove.org/wp-content/uploads/2020/05/Account-Transfer_Form.pdf">
            accounts transfer form
          </a>
        </p>
        <p>
          <strong>IMPORTANT</strong>: When you have given these instructions for transfer, please notify Preemptive Love’s Accounting Office at <a href="mailto:leigh.saxon@preemptivelove.org">leigh.saxon@preemptivelove.org</a> providing the following:
        </p>
        <ul>
          <li>Number of shares you are giving</li>
          <li>Company in which stock is held</li>
          <li>Date of expected transaction</li>
        </ul>
        <p>For example: Transferring 40 shares in Coca-Cola on April 15th, 2020</p>
        <p>
          If you have any questions, please contact Leigh at <a href="mailto:leigh.saxon@preemptivelove.org">leigh.saxon@preemptivelove.org</a>
        </p>
        <p>This will allow us to fully and accurately acknowledge your gift with a tax-deductible receipt.</p>
      </>
    ),
  },
  {
    name: "Bank Withdrawal",
    content: (
      <>
        <p>
          The most secure way to donate with zero processing fees is by setting up an ACH withdrawal. Email your
          completed{" "}
          <a href="https://preemptivelove.org/wp-content/uploads/2020/05/Preemptive-Love-ACH-Authorization-2020.pdf">
            ACH Authorization Form
          </a>{" "}
          to our Donor Care &amp; Digital Campaign Manager, Brooklyn Penn, at{" "}
          <a href="mailto:brooklyn.penn@preemptivelove.org">brooklyn.penn@preemptivelove.org</a>.
        </p>
      </>
    ),
  },
  {
    name: "Donor Advised Funds",
    content: (
      <>
        <p>[DAF WIDGET GOES HERE]</p>
        <p>Don’t see your Donor-Advised Fund in the menu above?</p>
        <p>
          Contact your fund administrator to request a distribution/beneficiary form or download the appropriate form(s) from your administrator’s website.
        </p>
        <p>
          If you include Preemptive Love in your plans, please let us know and use our legal name and federal tax ID.
        </p>
        <Table size="sm" borderless>
          <tbody>
            <tr>
              <th>Legal Name</th>
              <td>Preemptive Love Coalition</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                1300 Darbyton Dr.<br />
                Hewitt, TX 76643
              </td>
            </tr>
            <tr>
              <th>Federal Tax ID</th>
              <td>26-2450109</td>
            </tr>
          </tbody>
        </Table>
      </>
    ),
  },
  {
    name: "Check",
    content: (
      <>
        <p>
         Address all checks to Preemptive Love. If you’d like to designate your gift to a specific campaign, write the campaign name in the memo, and we’ll make sure your gift goes where it is needed most! Send the checks to:
        </p>
        <p>
          Preemptive Love Coalition
          <br />
          P.O. Box 207647
          <br />
          Dallas, TX 75320-7647
        </p>
      </>
    ),
  },
  {
    name: "Wire Transfer",
    content: (
      <>
        <p>
          Contact Donor Care &amp; Digital Campaign Manager, Brooklyn Penn,{" "}
          <a href="mailto:brooklyn.penn@preemptivelove.org">brooklyn.penn@preemptivelove.org</a> for wire transfer
          details.
        </p>
      </>
    ),
  },
  {
    name: "IRA",
    content: (
      <>
        <p>
          If you’re 70 ½ years or older, you can donate up to $100,000 from your IRA to Preemptive Love without having
          to paying income tax on the donation.
        </p>
      </>
    ),
  },
]
