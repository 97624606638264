import * as Yup from "yup"

export default Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Please enter a real name - minimum of two characters")
    .max(70, "Please enter a real name - that looks like too many characters")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Please enter a real name - minimum of two characters")
    .max(70, "Please enter a real name - that looks like too many characters")
    .required("Required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
  phone: Yup.string()
    .required("Required"),
  address1: Yup.string()
    .required("Required"),
  city: Yup.string()
    .required("Required"),
  postal: Yup.number()
    .required("Required"),
})
