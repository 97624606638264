import ReactRecaptcha3 from "react-google-recaptcha3";

export default async function verifyReCaptcha() {
  try {
    const newToken = await ReactRecaptcha3.getToken()
    return newToken
  } catch(error) {
    console.log(error)
  }
};
