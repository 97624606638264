  // https://stripe.com/docs/stripe-js/reference#element-options
  export default {
    style: {
      base: {
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        color: "#243746",
      },
    },
    classes: {
      base: "pldf-form-control",
      focus: "focus",
      invalid: "is-invalid",
    },
  }