import React from "react"

import StripeCardForm from "./StripeCardForm"

export default function StripeElements(props) {

  return (
        <StripeCardForm handleResult={props.handleToken} buttonText={props.buttonText} />
    
  )
}
