import React, { useContext, useState } from "react"
import { Formik, Form } from "formik"

import Context from "../../context"
import validationSchema from "./validationSchema"
import { Header, Button, LocationSearch } from "../../components"
import { FormikField } from "../../utilities/formik"

import countries from "../../utilities/countries"

export default function YourInformation(props) {
  const context = useContext(Context)

  // const [isAddressSelected, setIsAddressSelected] = useState(false)
  const [country, setCountry] = useState(context.country)
  // const [query, setQuery] = useState()

  const handleSubmit = async (values, actions) => {
    context.setFirstName(values.firstName)
    context.setLastName(values.lastName)
    context.setEmail(values.email)
    context.setPhone(values.phone)
    context.setIsCompany(values.isCompany)
    context.setCompany(values.company)
    // if (context.showMultiAddressFields) {
      context.setAddress1(values.address1)
      context.setAddress2(values.address2)
      context.setCity(values.city)
      context.setRegion(values.region)
      context.setPostal(values.postal)
    // }
    // if (!context.showMultiAddressFields && !isAddressSelected ) {
    //   context.setCountry(country)
    //   context.setAddress1(query)
    // }
    context.nextStep("review")
  }

  const handleCountryChange = ({target}) => { // this isn't being handled directly by Formik because value is needed immediately
    setCountry(target.value)
    context.setCountry(target.value)
    document.getElementsByName('country').value = target.value;
  }

  // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md <- this is the response format

  const handleSelection = (selection) => {
    if (selection.address && selection.text) {
      const address = `${selection.address} ${selection.text}`
      context.setAddress1(address)
    }
    if (!selection.address && selection.text) {
      const address = selection.text
      context.setAddress1(address)
    }
    if ( selection.context.find(context => context.id.includes("place") ) ) {
      const city =  selection.context.find(context => context.id.includes("place")).text
      context.setCity(city)
    }
    if ( selection.context.find(context => context.id.includes("region") ) ) {
      const region =  selection.context.find(context => context.id.includes("region")).short_code.replace(`${country}-`, "")
      context.setRegion(region)
    }
    if ( selection.context.find(context => context.id.includes("country") ) ) {
      const country =  selection.context.find(context => context.id.includes("country")).short_code.toUpperCase()
      context.setCountry(country)
    }
    if ( selection.context.find(context => context.id.includes("postcode") ) ) {
      const postcode =  selection.context.find(context => context.id.includes("postcode")).text
      context.setPostal(postcode)
    }
  }

  return (<>

    <Header title="Your Information" />

    <Formik
      initialValues={ context }
      validationSchema={ validationSchema }
      onSubmit={ handleSubmit }
    >
    { ({ touched, errors, status, values }) => <Form>

      <div className="pldf-form-row">
      <div className="pldf-col">

        <FormikField {...{ errors, touched }}
          name="firstName"
          placeholder="First name"
        />

      </div>
      <div className="pldf-col">

        <FormikField {...{ errors, touched }}
          name="lastName"
          placeholder="Last name"
        />

      </div>
      </div>

      <FormikField {...{ errors, touched }}
        type="email"
        name="email"
        placeholder="Email"
      />

      <FormikField {...{ errors, touched }}
        name="phone"
        placeholder="Phone number"
        type="tel"
      />

      {/* <div className="pldf-address-switch">
        <Button variant="link" className="pldf-address-switch-button" onClick={()=> context.setShowMultiAddressFields(prev => !prev)}>
          <small>{context.showMultiAddressFields ? `Search for address` : `Enter address manually`}</small>
        </Button>
      </div> */}

      {/* <div className="pldf-form-group">
        <select className="pldf-form-control" value={country} onChange={handleCountryChange}>
          {Object.keys(countries).map((name, key) => (
              <option value={countries[name]} key={key}>{name}</option>
            ))}
        </select>
      </div> */}
      <FormikField {...{ errors, touched }}
        as="select"
        name="country"
        value={country}
        onChange={handleCountryChange}
        options={countries}
      />

      <div className="pldf-form-group">
        <MailingAddress {...{touched, errors}} />
      </div>

      <FormikField {...{ errors, touched }}
        type="checkbox"
        name="isCompany"
        label="My donation is on behalf of an organization."
      />

      { values.isCompany && (
        <FormikField {...{ errors, touched }}
          style={{ marginTop: "-1em" }}
          name="company"
          placeholder="Name of organization"
        />
      ) }

      <Button type="submit" id="information-submit" block>Continue</Button>

      { status && status.msg && (
        <div className={`pldf-status pldf-status-${status.color}`}>
          { status.msg }
        </div>
      ) }

    </Form> }
    </Formik>


  </>)

}


const MailingAddress = ({ touched, errors }) => {

  const context = useContext(Context)
  const country = context.country
  function validateState(state) {
    let error;
    if((state === '' || state === undefined) && (country === 'US' || country === 'CA')){
      error = 'Required';
    }
    else{
      error = '';
    }
    return error;
  }

  return (
    <>
      <FormikField
        {...{ errors, touched }}
        name="address1"
        placeholder="Address"
      />
      <FormikField
        {...{ errors, touched }}
        name="address2"
        placeholder="Apt #"
      />
      <div className="pldf-form-row">
      <div className="pldf-col">
        <FormikField
          {...{ errors, touched }}
          name="city"
          placeholder="City"
        />
      </div>
      <div className="pldf-col">
        <FormikField
          {...{ errors, touched }}
          name="region"
          placeholder="State / Region"
          validate={validateState}
        />
      </div>
      </div>
      <FormikField
        {...{ errors, touched }}
        name="postal"
        placeholder="Zip / Postal Code"
      />
    </>
  )
}
