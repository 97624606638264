import React, { useContext } from "react"
import classnames from "classnames"

import "./styles.scss"
import Context from "../../context"
import { Amount } from "../../views"


export default () => {

  const {
    formLayout,
    setIsModalOpen,
    buttonClass,
    buttonText,
  } = useContext( Context )
  const classes = classnames(`pldf-launcher`, `pldf-layout-${formLayout}`)

  return (

    <div className={classes}>
      { ( formLayout === "mini" || ! formLayout ) && (
        <Amount />
      ) }
      { formLayout === "button" && (
        <button
          className={ classnames( "btn btn-primary", buttonClass ) }
          onClick={ () => setIsModalOpen( true ) }
        >
          { buttonText }
        </button>
      ) }
    </div>

  )

}
