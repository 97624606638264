import React, { useState, useEffect, useContext } from "react"
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from "react-stripe-elements"
import classnames from "classnames"

import { Button } from "../"
import Context from "../../context"
import config from "./config"

export default injectStripe(_StripeCardForm)

function _StripeCardForm(props) {
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false)
  const [isExpiryComplete, setIsExpiryComplete] = useState(false)
  const [isCvcComplete, setIsCvcComplete] = useState(false)

  const context = useContext(Context)

  const isDisabled = !isFormComplete || context.isSubmitting || context.error.messsage


  const handleChange = ({ elementType, complete, error }) => { // errors not from payment processing, but from input validation
    if (error) {
      context.setError({ message: error.message })
    } else {
      context.setError({ message: undefined })
    }

    if (elementType === "cardNumber") setIsCardNumberComplete(complete)
    if (elementType === "cardExpiry") setIsExpiryComplete(complete)
    if (elementType === "cardCvc") setIsCvcComplete(complete)
  }

  const handleSubmit = (event) => {
    if (isDisabled) return
    event.preventDefault()
    if (props.stripe) props.stripe.createToken().then(props.handleResult)
  }

  /*
   * Stripe Error Codes / Handling
   * -> https://stripe.com/docs/error-codes
   * -> https://stripe.com/docs/api/errors
   * -> https://stripe.com/docs/testing
   *
   * Stripe has many error codes. We likely don't need them all. The ones that seem most relvant
   * were added below. They're releated to input data and bank response. More can be added if needed.
   *
   */

  useEffect(() => {
    const formfilled = isCardNumberComplete && isCvcComplete && isExpiryComplete
    setIsFormComplete(formfilled)
  }, [isCardNumberComplete, isCvcComplete, isExpiryComplete])

  useEffect(()=>{
    return () => {
      context.setIsCardElReady(false)
      context.setIsExpiryElReady(false)
      context.setIsCvcElReady(false)
    }
  },[]) // eslint-disable-line

  const classes = classnames('pldf-card-details-form', {
    'pldf-stripe-is-ready': context.isCardElReady && context.isExpiryElReady && context.isCvcElReady
  })

  return (
    <form className={classes} onSubmit={handleSubmit} >
      <div className="pldf-form-row">
        <div className="pldf-col-12 pldf-form-margin-bottom">
          <div className="pldf-form-loading">
            <CardNumberElement {...config} onChange={handleChange} onReady={()=>context.setIsCardElReady(true)} />
          </div>
        </div>


        <div className="pldf-col pldf-form-margin-bottom ">
          <div className="pldf-form-loading">
          <CardExpiryElement {...config} onChange={handleChange} onReady={()=>context.setIsExpiryElReady(true)} />
          </div>
        </div>
        <div className="pldf-col pldf-form-margin-bottom pldf-form-block">
          <div className="pldf-form-loading">
          <CardCVCElement {...config} onChange={handleChange} onReady={()=>context.setIsCvcElReady(true)} />
          </div>
        </div>
      </div>

      <Button type="submit" block disabled={isDisabled}>
        {context.stripeToken ? "Update" : "Continue"}
      </Button>

    </form>
  )
}
