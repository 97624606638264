import React from "react"
import classnames from "classnames"


export default ({
  children,
  className,
  onClick,
  disabled,
  style,
  type = "button",
  variant = "primary",
  block, id
  }) => {

  const classNames = classnames(
    "pldf-btn",
    `pldf-btn-${variant}`,
    className,
    { "pldf-block": block }
  )

  return (
    <button className={ classNames } {...{ disabled, onClick, style, type, id }}>
      { children }
    </button>
  )

}
