import React, { createContext, useState, useEffect, useCallback } from "react"

import createDonation from "./utilities/plc-services/create-donation"
import fetchAccessToken from "./utilities/plc-services/fetch-access-token"
import gtmEvent from "./utilities/gtmEvent"
import capitalize from "./utilities/capitalize"
import dollarsToCents from "./utilities/dollarsToCents"
import { closeDriftSidebar } from "./utilities/drift"
import initiateReCaptcha from "./utilities/initiateReCaptcha";
import verifyReCaptcha from "./utilities/verifyReCaptcha"

const Context = createContext()
export default Context

export const Provider = ({ htmlData, children, isStripeLoaded }) => {

  // Views
  const viewList = [
    "enter-amount",
    "payment-method",
    "your-information",
    "ways-to-give",
    "other-methods",
    "review",
    "processing",
    "complete",
  ]
  const feeIncreasePercent = 3

  // User Interface
  const formLayout = htmlData.formLayout || "mini"
  const [isModalOpen, setIsModalOpen] = useState()
  const [viewIndex, setViewIndex] = useState(0)
  const [view, setView] = useState(viewList[viewIndex])
  const [previousView, setPreviousView] = useState()
  const [showAllMethods, setShowAllMethods] = useState(false)
  const [shouldReturnToReview, setShouldReturnToReview] = useState(false)

  // Form Values
  const initialAmount = parseInt(htmlData.amount) > 0 ? parseInt(htmlData.amount) : undefined

  const [hasCoveredFees, setHasCoveredFees] = useState(false)
  const [amount, setAmount] = useState(initialAmount) // unformatted amount in dollars 25 = $25
  const [amountTotal, setAmountTotal] = useState(amount)
  const [frequency, setFrequency] = useState(htmlData.frequency || "monthly")
  const [fundraiser] = useState(htmlData.campaign_name || "general")

  const [firstName, setFirstName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [suffix, setSuffix] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("") // state/region
  const [country, setCountry] = useState("US")
  const [postal, setPostal] = useState("")
  // const [showMultiAddressFields, setShowMultiAddressFields] = useState(false)

  // const [billing, setBilling] = useState(false) // turned off for now, but maybe want it later?

  const [isCompany, setIsCompany] = useState(false)
  const [company, setCompany] = useState("")

  const [stripeToken, setStripeToken] = useState()
  const [paymentRequest, setPaymentRequest] = useState(null)

  const [paymentMethod, setPaymentMethod] = useState()
  const [paypalNonce, setPaypalNonce] = useState()
  const [deviceData, setDeviceData] = useState()
  const [paypalAccountEmail, setPayPalAccountEmail] = useState()

  // script loading
  const [paypalLoaded, setPaypalLoaded] = useState(false)
  const [braintreeClientLoaded, setBraintreeClientLoaded] = useState(false)
  const [braintreeCheckoutLoaded, setBraintreeCheckoutLoaded] = useState(false)
  const [braintreeDataCollectorLoaded, setBraintreeDataCollectorLoaded  ] = useState(false)

  // element loading
  const [isCardElReady, setIsCardElReady] = useState(false)
  const [isExpiryElReady, setIsExpiryElReady] = useState(false)
  const [isCvcElReady, setIsCvcElReady] = useState(false)
  const [isPayPalElReady, setIsPayPalElReady] = useState(false)
  const [failedToLoadPayPal, setFailedToLoadPaypal] = useState(true)

  const [accessToken,setAccessToken] = useState()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [error, setError] = useState()

  const recaptchaRef = React.createRef()

  const clearForm = () => {
    setHasCoveredFees(false)
    setAmount(initialAmount)
    setFrequency(htmlData.frequency || "monthly")
    setFirstName("")
    setMiddleName("")
    setLastName("")
    setSuffix("")
    setEmail("")
    setPhone("")
    setAddress1("")
    setCity("")
    setRegion("")
    // not resetting country
    setPostal("")
    setIsCompany(false)
    setCompany("")
    setStripeToken()
    setPaymentMethod()
    setPaypalNonce()
    setDeviceData()
    setPayPalAccountEmail()
  }

  useEffect(() => {
    initiateReCaptcha()
  }, []);

  useEffect(()=>{
    if (hasCoveredFees) setAmountTotal(amount + (amount * feeIncreasePercent / 100))
    else setAmountTotal(amount)
  },[amount, hasCoveredFees])

  // Payment Processing
  const processPayment = async () => {
    if ( paymentMethod !== "Stripe" && paymentMethod !== "PayPal" && paymentMethod !== "Payment Request" )
      return

    const dollarAmount = parseFloat(amountTotal.toFixed(2))
    const centsAmount = dollarsToCents(amountTotal)
    const amountType = amount === initialAmount ? "suggested" : "custom"

    const onPaymentSuccess = () => {
      gtmEvent({
        eventCategory: "Donation",
        eventAction: "Succeeded",
        eventLabel: capitalize( frequency ),
        eventValue: dollarAmount,
        donationFrequency: frequency,
        paymentMethod,
        amountType,
        ecommerce: {
          purchase: {
            actionField: {
              id: new Date().getTime(),
              //affiliation: "Love Anyway Website",
              revenue: dollarAmount,
              tax: "0",
              shipping: "0"
            },
            products: [
              {
                name: capitalize( frequency ),
                id: frequency,
                price: dollarAmount,
                brand: "Preemptive Love",
                category: "Donation",
                variant: capitalize( frequency ),
                quantity: 1
              }
            ]
          }
        }
      })
    }

    const onPaymentFail = ( errorCode ) => {
      gtmEvent({
        eventCategory: "Donation",
        eventAction: "Failed",
        eventLabel: capitalize( frequency ),
        eventValue: dollarAmount,
        donationFrequency: frequency,
        paymentMethod,
        amountType,
        errorCode,
      })
    }

    const streetAddress = address2 ? `${address1} ${address2}` : address1
    let fundraiserName = fundraiser
    if(fundraiser == "Home" || fundraiser == "general"){
      fundraiserName = frequency == "monthly" ? "monthly" : fundraiser;
    }

    let payload = {
      donor: {
        firstName,
        middleName,
        lastName,
        suffix,
        email,
        phone,
        shippingAddress: {
          street: streetAddress,
          city,
          region,
          country,
          postalCode: postal,
        },
      },
      transaction: {
        amount: centsAmount,
        amountType,
        paymentProcessor: paymentMethod === "Stripe" || paymentMethod === "Payment Request" ? "Stripe" : paymentMethod,
        paymentMethod: {
          token: paymentMethod === "PayPal" ? paypalNonce : stripeToken.id,
        },
        frequency,
        hasCoveredFees,
        feeIncreasePercent,
      },
      campaign: {
        name: fundraiserName,
      },
      utm: window.roundaboutGetUTM && window.roundaboutGetUTM(),
      form: {
        source: window !== undefined && `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
        context: htmlData.formContext || null,
        layout: formLayout,
      }
    }
    if ( isCompany ) {
      payload.organization = {
        name: company,
      }
    }
    const [success, error] = await createDonation( payload )

    if (success) {
      setError({ message: undefined })
      setViewIndex(viewList.length - 1) // complete
      setPaypalNonce()
      onPaymentSuccess()
    } else {
      setError({ message: error.message })
      nextStep("review")
      onPaymentFail( error.code )
    }

    setIsSubmitting(false)

  }

  // Navigation functions
  const previousStep = (v) => {
    if (view !== "other-methods") setPreviousView()
    if (shouldReturnToReview) {
      if (stripeToken) {
        setShouldReturnToReview(false)
        const reviewStep = viewList.indexOf("review")
        setViewIndex(reviewStep)
      } else {
        setError({message: "Please select a payment method."})
      }
    } else if (v) {
      const definedStep = viewList.indexOf(v)
      setViewIndex(definedStep)
    } else {
      if (viewIndex === 0) return
      if (previousView && view !== "other-methods") setViewIndex(viewList.indexOf(previousView))
      else if (viewIndex > 0) setViewIndex(prev => prev - 1)
    }
  }
  const nextStep = (v, fromReview) => {
    if (fromReview) {
      setShouldReturnToReview(true)
    } else if (!fromReview) {
      setShouldReturnToReview(false)
    }

    if (shouldReturnToReview) {
      const reviewStep = viewList.indexOf("review")
      setViewIndex(reviewStep)
    } else if (v) {
      const definedStep = viewList.indexOf(v)
      if (view !== "ways-to-give") setPreviousView(view)
      setViewIndex(definedStep)
    } else {
      if (viewIndex === (viewList.length - 1)) return
      if (viewIndex < (viewList.length - 1)) {
        if (view !== "ways-to-give") setPreviousView(view)
         setViewIndex(prev => prev + 1)}
    }
  }

  const close = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setViewIndex(0)
    }, 500)
    view === "complete" && clearForm()
  }

  useEffect( () => {
    if ( isModalOpen === undefined ) return
    if ( isModalOpen ) {
      closeDriftSidebar()
      gtmEvent({
        eventCategory: "Modal",
        eventAction: "Opened",
      })
    } else {
      gtmEvent({
        eventCategory: "Modal",
        eventAction: "Closed",
      })
    }
  }, [isModalOpen] )

  useEffect(() => {
    setView(viewList[viewIndex])
    gtmEvent({
      eventCategory: "Step",
      eventAction: `Loaded ${viewList[viewIndex]}`,
    })
  }, [viewIndex]) // eslint-disable-line

  const submitDonation = async () => {
      nextStep("processing")
      setIsSubmitting(true)
      try {
        const isNotRobot = await verifyReCaptcha()
        if (isNotRobot) {
          processPayment()
        } else if (!isNotRobot) {
          setError({ message: "Oh no! Our robots think that you're a robot! Please contact us so that we can help you with your donation" })
          nextStep("review")
        }
      } catch (e)  {
        return console.error(e)
      }
  }

  /*
  * The following useEffect runs when context is mounted (aka when the donate form initializes).
  * It checks to see if PayPal-related functions exist:
  * -> paypal(), braintree.client(), braintree.paypalCheckout(), & braintree.dataCollector()
  * If they do not exist, they are imported from PayPal/Braintree.
  *
  * These are necessary for running any of the PayPal component.
  *
  * The current rendering configuration for rendering a single button is being handled as a query to the SDK.
  * It would be ideal if we could move that config to JS.
  * -> https://developer.paypal.com/docs/checkout/integration-features/standalone-buttons/
  * -> https://developer.paypal.com/docs/checkout/reference/customize-sdk/#components
  *
  * Debugging is turned on as a query in the url for testing - please turn off during production.
  * Vault is set as true in the query.
  *
  * Client ID is what determins if it is sandbox or production.
  *
  * It might be a good idea to move these from context, but not super necessary...
  *
  * PLC PayPal Sandbox Personal ("Customer")
  * email: sb-zwgtx1171393@personal.example.com
  * password: u/G}]t@2
  *
  * PLC PayPal Sandbox Business ("PLC")
  * email: sb-a8uoy1184069@business.example.com
  * password Ok#O3tZb
  */
  useEffect(() => {
    if (!process.env.REACT_APP_PAYPAL_CLIENT_ID) return setFailedToLoadPaypal(true)
    if (!window.paypal) {
      const paypalJS = document.createElement("script")
      paypalJS.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&disable-funding=credit,card,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort`
      paypalJS.onload = () => {
        setPaypalLoaded(true)
      }
      document.body && document.body.appendChild(paypalJS)
    }
    if (!window.braintree || !window.braintree.client) {
      const braintreeJS = document.createElement("script")
      braintreeJS.src = "https://js.braintreegateway.com/web/3.58.0/js/client.min.js"
      braintreeJS.onload = () => {
        setBraintreeClientLoaded(true)
      }
      document.body && document.body.appendChild(braintreeJS)
    }
    if (!window.braintree || !window.braintree.paypalCheckout) {
      const checkoutJS = document.createElement("script")
      checkoutJS.src = "https://js.braintreegateway.com/web/3.58.0/js/paypal-checkout.min.js"
      checkoutJS.onload = () => {
        setBraintreeCheckoutLoaded(true)
      }
      document.body && document.body.appendChild(checkoutJS)
    }
    if (!window.braintree || !window.braintree.dataCollector) {
      const collectorJS = document.createElement("script")
      collectorJS.src = "https://js.braintreegateway.com/web/3.58.0/js/data-collector.min.js"
      collectorJS.onload = () => {
        setBraintreeDataCollectorLoaded(true)
      }
      document.body && document.body.appendChild(collectorJS)
    }
  }, [])

  const getAccessToken = useCallback(async () => {
    if (accessToken) return

    const [token] = await fetchAccessToken()

    if (token) setAccessToken(token)
    else setFailedToLoadPaypal(true)
  }, [accessToken, setAccessToken])

  useEffect(()=>{
    getAccessToken()
  },[getAccessToken])

  useEffect(()=>{
    if (stripeToken && paymentMethod === "Payment Request") submitDonation()
    if (paypalNonce && paymentMethod === "PayPal") submitDonation()
  }, [paymentMethod, stripeToken, paypalNonce]) // eslint-disable-line

  useEffect(()=>{
    isStripeLoaded ? setError(null) : setError({message: "Oh no! It looks like our Payment Processor is unavailable at this time. Please try again later or reach out to our team."})
  }, [isStripeLoaded])

  return (
    <Context.Provider
      value={{
        submitDonation,
        error,
        setError,
        view,
        setView,
        viewList,
        viewIndex,
        frequency,
        setFrequency,
        amount,
        setAmount,
        feeIncreasePercent,
        hasCoveredFees,
        setHasCoveredFees,
        email,
        setEmail,
        phone,
        setPhone,
        firstName,
        setFirstName,
        middleName,
        setMiddleName,
        lastName,
        setLastName,
        suffix,
        setSuffix,
        isModalOpen,
        setIsModalOpen,
        stripeToken,
        setStripeToken,
        formLayout,
        fundraiser,
        close,
        previousStep,
        nextStep,
        paypalLoaded,
        braintreeCheckoutLoaded,
        braintreeClientLoaded,
        braintreeDataCollectorLoaded,
        paymentMethod, setPaymentMethod,
        paypalNonce, setPaypalNonce,
        deviceData, setDeviceData,
        isCompany, setIsCompany,
        address1, setAddress1,
        city, setCity,
        region, setRegion,
        country, setCountry,
        postal, setPostal,
        company, setCompany,
        isSubmitting,
        accessToken,
        showAllMethods,
        setShowAllMethods,
        modalImageUrl: htmlData.modalImageUrl,
        modalImageAlt: htmlData.modalImageAlt,
        buttonClass: htmlData.buttonClass,
        buttonText: htmlData.buttonText || "Donate Now",
        isCardElReady,
        setIsCardElReady,
        isExpiryElReady,
        setIsExpiryElReady,
        isCvcElReady,
        setIsCvcElReady,
        isPayPalElReady, setIsPayPalElReady,
        shouldReturnToReview,
        amountTotal,
        paypalAccountEmail, setPayPalAccountEmail,
        failedToLoadPayPal, setFailedToLoadPaypal,
        address2, setAddress2,
        // showMultiAddressFields, setShowMultiAddressFields,
        paymentRequest, setPaymentRequest,
        isStripeLoaded,
        recaptchaRef
      }}>
      {children}
    </Context.Provider>
  )

}
