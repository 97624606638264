import React, { useContext } from "react"
import { ArrowLeft, X } from "react-feather"

import Context from "../../context"
import gtmEvent from "../../utilities/gtmEvent"


export default function Header({
  previousView,
  title,
}) {

  const context = useContext( Context )
  const handleCloseClick = () => {
    context.close()
    gtmEvent({
      eventAction: "Close button",
      eventCategory: "Clicked",
    })
  }

  return (
    <header className={`pldf-nav pldf-${context.view}`}>
      {context.viewIndex !== 0 && context.viewIndex !== context.viewList.length - 1 && (
        <ArrowLeft
          className="pldf-icon-left"
          onClick={ () => context.previousStep( previousView || null ) }
        />
      )}
      <X className="pldf-icon-right" onClick={ handleCloseClick } />
      { title && (
        <h2 className="pldf-heading pldf-h4">{ title }</h2>
      ) }
    </header>
  )

}
