// This is the way
import React, { useContext } from "react"
import classnames from "classnames"

import Context from "../../context"


export default () => {
  const context = useContext(Context)

  const classesMonthly = classnames("pldf-tab", { "pldf-selected": context.frequency === "monthly" })
  const classesOnce = classnames("pldf-tab", { "pldf-selected": context.frequency === "once" })

  return (
    <div className="pldf-tabs pldf-frequency">
      <button className={classesMonthly} onClick={() => context.setFrequency("monthly")}>
        Become a Member
      </button>
      <button className={classesOnce} onClick={() => context.setFrequency("once")}>
        Give Once
      </button>
    </div>
  )
}
