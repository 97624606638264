import React, { useContext, useEffect } from "react"
import { CreditCard } from "react-feather"
import { Alert } from "react-bootstrap"
import classnames from "classnames"

import Context from "../../context"
import { Header, StripeElements, StripePaymentRequestButton } from "../../components"
import { Button } from "react-bootstrap"

export default () => {
  const context = useContext(Context)

  const handleToken = ({ token }) => {
    if (token && token.id) {
      context.setPaymentMethod("Stripe")
      context.setStripeToken(token)
      context.setPaypalNonce()
      context.nextStep("your-information")
    }
  }

  useEffect(() => {
    if (
      context.paypalLoaded &&
      context.braintreeClientLoaded &&
      context.braintreeCheckoutLoaded &&
      context.braintreeDataCollectorLoaded &&
      context.accessToken
    ) {
      /*
       * Client Authorization
       * -> Two options: Client Token, Tokenization Key
       * -> We are using: Tokenization Key
       * -> Read about the differences => https://developers.braintreepayments.com/guides/authorization/overview
       * -> Read about tokenization key => https://developers.braintreepayments.com/guides/authorization/tokenization-key/javascript/v3
       */

      window.braintree.client
        .create({
          authorization: context.accessToken,
        })
        .then(async (clientInstance) => {
          /*
           * Device data collection is required for reducing decline rate on non-recurring transactions
           * -> https://developers.braintreepayments.com/guides/paypal/vault/javascript/v3#collecting-device-data
           *
           * This function is returning both the result of the window.braintree.dataCollector.create() function
           * and the previously obtained clientInstance created above with window.braintree.client.create().
           * The latter of which is needed in order to create the PayPal Checkout Instance.
           */
          if (context.deviceData) {
            return { dataCollectorInstance: undefined, clientInstance }
          }

          const dataCollectorInstance = await window.braintree.dataCollector.create({
            client: clientInstance,
            paypal: true,
          })
          return { dataCollectorInstance, clientInstance }
        })
        .then(({ dataCollectorInstance, clientInstance }) => {
          /*
           * At this point, dataCollectorInstance.deviceData will be sent to context.
           *
           * To continue beyond this point, a PayPal account need to be linked to the Braintree account!
           */
          if (dataCollectorInstance) {
            context.setDeviceData(dataCollectorInstance.deviceData)
          }

          return window.braintree.paypalCheckout.create({
            client: clientInstance,
          })

          // dataCollectorInstance.teardown(); // to cleanly reset integration...
        })
        .then(async function (paypalCheckoutInstance) {
          /*
           * PayPal Checkout Component
           * This is what renders the button that launches checkout/vault flow.
           * -> https://braintree.github.io/braintree-web/current/PayPalCheckout.html (scroll down to "Integrate Vault Flow with PayPal SDK")
           * -> https://developer.paypal.com/docs/checkout/
           */


          const buttons = await window.paypal
            .Buttons({
              createBillingAgreement: function () {
                return paypalCheckoutInstance.createPayment({
                  // https://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
                  flow: "vault",
                  intent: "capture",
                  offerCredit: "false",
                  displayName: "Preemptive Love",
                  enableShippingAddress: true,
                })
              },

              onApprove: function (data, actions) {
                return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
                  payload?.details?.email && context.setEmail( payload.details.email)
                  payload?.details?.firstName && context.setFirstName( payload.details.firstName)
                  payload?.details?.lastName && context.setLastName( payload.details.lastName)

                  payload?.details?.shippingAddress?.city && context.setCity(payload.details.shippingAddress.city)
                  payload?.details?.shippingAddress?.countryCode && context.setCountry(payload.details.shippingAddress.countryCode)
                  payload?.details?.shippingAddress?.line1 && context.setAddress1(payload.details.shippingAddress.line1)
                  payload?.details?.shippingAddress?.line2 && context.setAddress2(payload.details.shippingAddress.line2)
                  payload?.details?.shippingAddress?.postalCode && context.setPostal(payload.details.shippingAddress.postalCode)
                  payload?.details?.shippingAddress?.state && context.setRegion(payload.details.shippingAddress.state)
                  payload?.details?.shippingAddress?.region && context.setRegion(payload.details.shippingAddress.region)

                  context.setPaymentMethod("PayPal")
                  context.setStripeToken()
                  context.setPaypalNonce(payload.nonce)
                  context.setPayPalAccountEmail(payload.details.email)
                })
              },

              onCancel: function (data) {
                // handle case where user cancels
                console.log("checkout.js payment cancelled", JSON.stringify(data, 0, 2))
              },

              onError: function (err) {
                // handle case where error occurs
                console.error("checkout.js error", err)
              },

              style: {
                color: "silver",
                shape: "rect",
                size: "responsive",
                label: "paypal",
                tagline: false,
                height: 55, // can't be higher
              },
            })
            if (!!buttons) context.setFailedToLoadPaypal(false)
            buttons.render("#paypal-button")

          // paypalCheckoutInstance.teardown();
        })
        .then(function () {
          // The PayPal button will be rendered in an html element with the id
          // `paypal-button`. This function will be called when the PayPal button
          // is set up and ready to be used.
          context.setIsPayPalElReady(true)
        })
        .catch(function (err) {
          context.setFailedToLoadPaypal(true)
          console.log("FAILED TO CREATE COMPONENT", err) // Handle component creation error
        })
    }

    return () => { context.setIsPayPalElReady(false) }
    // eslint-disable-next-line
  }, [
    context.paypalLoaded,
    context.braintreeClientLoaded,
    context.braintreeCheckoutLoaded,
    context.braintreeDataCollectorLoaded,
    context.setPayPalNonce,
    context.setDeviceData,
    context.accessToken
  ])

  const classes = classnames("pldf-paypal-button-col", {
    "pldf-is-paypal-ready": context.isPayPalElReady,
    "pldf-show-keep-paypal": context.paypalNonce,
  })
  
  return (
    <div className="pldf-payment-method" id="pldf-view-payment">
      <Header title="Payment Method" />
      <div className="pldf-row">

        {context.error && context.error.message && (
          <div className="pldf-col-12"style={{ order: 2 }} >
            <Alert variant="warning" style={{ marginBottom: 0 }}>{context.error.message}</Alert>
          </div>
        )}

        {context.isStripeLoaded && (context.stripeToken && context.paymentMethod === "Stripe" ? <UpdateCard {...context} /> : context.paypalNonce ? <SelectCard {...context}/> : (
          <div className="pldf-col-12" style={{ order: 1 }}>
            <StripeElements handleToken={handleToken} />
          </div>
        ))}

        <div className="pldf-col-12" style={{ order: 2 }}>
        <p className="pldf-note">
          By submitting a payment, you agree to Preemptive Love&rsquo;s{" "}
          <a href="https://preemptivelove.org/terms-conditions/" target="_blank" rel="noopener noreferrer">
            terms & conditions
          </a>
          . Contributions are tax deductible in the US. Your donation is in{" "}
          <span style={{ display: "inline-block" }}>US dollars.</span>
        </p>
        </div>

        {!context.failedToLoadPayPal && (
          <>
          <div className="pldf-col-12" style={{ order: 2 }}>
            <div className="pldf-or-break">
              <span className="pldf-or-line"></span>
              <p className="pldf-other-ways-to-give">OR</p>
              <span className="pldf-or-line"></span>
            </div>
          </div>

          <div className="pldf-col-12" style={{ order: context.paypalNonce ? 1 : 3 }}>
            <div className={classes}>
              <div className="pldf-form-loading" id="paypal-button" />
              {context.paypalNonce && (<>
                {context.paypalAccountEmail && <p className="pldf-review-data">PayPal account with email: {context.paypalAccountEmail}</p>}
                <Button className="pldf-btn-keep-card" onClick={() => { 
                  context.paymentMethod !== "PayPal" && context.setPaymentMethod("PayPal")
                  context.nextStep("review") 
                  }} block>
                  Continue
                </Button>
              </>)}
            </div>
          </div>
          </>
        )}

        <div
          className="pldf-col-12"
          style={{ order: 3 }}
        >
          <StripePaymentRequestButton />
        </div>

        <Button
          href="/donate#ways-to-give"
          variant="link"
          className="pldf-btn-other-ways-to-give"
          style={{ order: 4 }}
          block>
          Other ways to give
        </Button>
      </div>
    </div>
  )
}


const UpdateCard = ({ stripeToken, setStripeToken, paymentMethod, setPaymentMethod, nextStep, setError }) => {
  return (
    <div className="pldf-col-12 pldf-row" style={{ margin: 0, order: stripeToken ? 1 : 3 }}>
      <div className="pldf-col-9 pldf-review-data">
        <p>{`Card ending in ${stripeToken.card.last4}`}</p>
      </div>
      <div className="pldf-col-3 pldf-col-change-link">
        <span className="pldf-change-link" onClick={() => {
            setError()
            setStripeToken()
          }} aria-label="Change card information">
          Change
        </span>
      </div>

      <Button
        onClick={() => {
          paymentMethod !== "Stripe" && setPaymentMethod("Stripe")
          nextStep("your-information")
        }}
        block>
        Continue
      </Button>
    </div>
  )
}

const SelectCard = ({ setPaypalNonce, setStripeToken, setPaymentMethod }) => {
  return (
    <div className="pldf-col-12" style={{ order: 3 }}>
      <Button
        className={`pldf-payment-method-btn pldf-payment-method-btn-card`}
        variant="light"
        type="button"
        onClick={() => {
          setPaypalNonce()
          setStripeToken()
          setPaymentMethod()
        }}>
        <span className="icon">
          <CreditCard />
        </span>
        <span className="name">Credit Card</span>
      </Button>
    </div>
  )
}
