import React, { useContext } from "react"

import { Header } from "../../components"
import toCamelCase from "../../utilities/toCamelCase"
import Context from "../../context"
import content from "./content"

export default () => {
  const context = useContext(Context)

  return (
    <div className="pldf-other-methods" id={`pldf-view-method-${toCamelCase(context.paymentMethod)}`}>
      <Header title={context.paymentMethod} />
      {content.find(item => item.name === context.paymentMethod).content}
    </div>
  )
}
