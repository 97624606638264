import React, { useContext } from "react"
import { Alert, Button as RbButton } from "react-bootstrap"

import { Header, Button } from "../../components"
import Context from "../../context"
import countries from '../../utilities/countries'
import formatCurrency from "../../utilities/formatCurrency"
import gtmEvent from "../../utilities/gtmEvent"

export default () => {
  const context = useContext(Context)

  const isComplete =
    context.amount > 0 &&
    (context.paymentMethod === "Stripe" || context.paymentMethod === "PayPal") &&
    (context.stripeToken || context.paypalNonce)

  return (
    <div className="pldf-review">
      <Header title="Review" previousView="your-information"/>

      {context.error && context.error.message && (
        <div className="pldf-alert pldf-alert-warning">{context.error.message}</div>
      )}

      <div className="pldf-row">
        <div className="pldf-col-9 pldf-review-data">
          <p>
            {context.frequency === "monthly"
              ? `Monthly gift of ${formatCurrency(context.amountTotal)}`
              : `One-time gift of ${formatCurrency(context.amountTotal)}`}
          </p>
        </div>
        <div className="pldf-col-3 pldf-col-change-link">
          <span
            className="pldf-change-link"
            onClick={() => context.nextStep("enter-amount", true)}
            aria-label="Change donation amount">
            Change
          </span>
        </div>
      </div>

      <div className="pldf-row">
        <div className="pldf-col-9 pldf-review-data">
          <p>
            {context.firstName} {context.lastName}
          </p>
          <p>{context.email}</p>
          <p>{context.phone}</p>
          {context.isCompany && <p>{context.company}</p>}
        </div>
        <div className="pldf-col-3 pldf-col-change-link">
          <span
            className="pldf-change-link"
            onClick={() => context.nextStep("your-information", true)}
            aria-label="Change your information">
            Change
          </span>
        </div>
      </div>

      <div className="pldf-row">
        <div className="pldf-col-9 pldf-review-data">
          <p>{context.address1}</p>
          {context.address2 && <p>{context.address2}</p>}
          <p>{context.city && `${context.city}, `}{context.region && `${context.region}, `}{context.postal && `${context.postal}`}</p>
          <p>{Object.keys(countries).find((key) => countries[key] === context.country)}</p>
        </div>
        <div className="pldf-col-3 pldf-col-change-link">
          <span className="pldf-change-link" onClick={() => context.nextStep("your-information", true)} aria-label="Change mailing address">
            Change
          </span>
        </div>
      </div>

      <div className="pldf-row">
        <div className="pldf-col-9 pldf-review-data">
          {context.paymentMethod === "Stripe" && context.stripeToken ? (
            <p className="info-type">Card ending in {context.stripeToken.card.last4}</p>
          ) : context.paymentMethod === "PayPal" && context.paypalNonce ? (
            <p className="info-type">PayPal <br />{context.paypalAccountEmail && ` (${context.paypalAccountEmail})`}</p>
          ) : (
            <p className="info-type">No payment method selected</p>
          )}
        </div>
        <div className="pldf-col-3 pldf-col-change-link">
          <span
            className="pldf-change-link"
            onClick={() => {
              context.setStripeToken()
              context.setPaypalNonce()
              context.setPaymentMethod()
              context.setError()
              context.nextStep("payment-method", true)
            }}
            aria-label="Change payment method">
            Change
          </span>
        </div>
      </div>

      { context.frequency === "monthly" ? (
        <Alert variant="success">
          <div style={{ lineHeight: '1' }}>
            <small><em>
              Thank you for your monthly gift that allows us to move quickly and act where help is needed most.
            </em></small>
          </div>
        </Alert>
      ) : (
        <Alert variant="info">
          <div className="d-flex align-items-center">
            <div style={{ lineHeight: '1' }}>
              <small><em>
                Make more impact. Become a member and join our community of peacemakers.
              </em></small>
            </div>
            <RbButton
              onClick={ () => {
                context.setFrequency( 'monthly' )
                gtmEvent({
                  eventCategory: "Monthly Upsell Button",
                  eventAction: "Clicked",
                })
              } }
              variant="light"
              size="sm"
              style={{
                whiteSpace: 'nowrap'
              }}
            >
              Give monthly
            </RbButton>
          </div>
        </Alert>
      ) }
      
      <Button
        className="pldf-submit-donation"
        onClick={() => context.submitDonation()}
        block
        aria-label="Process payment"
        disabled={!isComplete}>
        {context.isSubmitting 
          ? "Processing..." 
          : context.frequency === "monthly" 
            ? `Give ${formatCurrency(context.amountTotal)} every month` 
            : `Give ${formatCurrency(context.amountTotal)}`
        }
      </Button>
    </div>
  )
}
