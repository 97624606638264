import React from "react"
import { Field, ErrorMessage } from "formik"
import classnames from "classnames"

import toCamelCase from "./toCamelCase"


export const FormikField = ( props ) => {

  const name = props.name
    || ( props.label && toCamelCase( props.label ) )
    || ( props.placeholder && toCamelCase( props.placeholder ) )

  const fieldProps = {
    ...props,
    type: props.type || ( ! props.as && "text" ) || "",
    name,
    id: props.id || name,
    "aria-label": props["aria-label"] || props.label || props.placeholder,
    className: classnames( props.className, {
      "pldf-form-control": props.type !== "checkbox" && props.type !== "radio",
      "pldf-form-check-input": props.type === "checkbox" || props.type === "radio",
      "is-invalid": props.errors[name],
    } ),
  }
  const formcheckClass = classnames({"pldf-form-check": props.type === "checkbox" || props.type === "radio"})

  return <>

    <div className="pldf-form-group">

      <div className={formcheckClass}>

      {/* Checkbox and radio buttons */}
      { ( props.type === "checkbox" || props.type === "radio" ) ? <>

        {/* Multiple checkboxes or radio buttons */}
        { !! props.options ? <>
          {/* TO DO
          props.options.map( ( option, key ) => (
            <label htmlFor={ fieldProps.id } key={ key }>
              <Field { ...fieldProps } />
              { option.label }
            </label>
          ) )
          */}

        {/* Easy single checkbox or radio button */}
        </> : (
          <label htmlFor={ fieldProps.id }>
            <Field { ...fieldProps } />
            { props.label }
          </label>
        ) }

      {/* Not checkbox or radio */}
      </> : <>
        { props.label && (
          <label htmlFor={ fieldProps.id }>{ props.label }</label>
        ) }
      </> }

      {/* Select dropdown */}
      { props.as === "select" && <>
        <Field { ...fieldProps }>
          {Object.keys(props.options).map((name, key) => (
              <option value={props.options[name]} key={key}>{name}</option>
          ))}
        </Field>
      </> }

      {/* All others */}
      { ! props.as && props.type !== "checkbox" && props.type !== "radio" && <>
        <Field { ...fieldProps } />
      </> }

      <ErrorMessage
        name={ fieldProps.name }
        component={ ({ props, children }) => (
          <div className="invalid-feedback" {...{ props }}>{ children }</div>
        ) }
      />

      </div>

    </div>

  </>

}
