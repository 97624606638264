import React from "react"

import { Header } from "../../components"

export default function Complete( props ) {

  return (

    <div className="pldf-complete">

      <Header title="Complete" />

      <h2 className="pldf-h5" style={{ marginTop: "1rem" }}>
        Thank you for joining our giving community and helping to stop the spread of violence!
      </h2>
      <p className="pldf-subheading">A tax receipt will be emailed to you for your donation.</p>

    </div>

  )

}
