import toCamelCase from "./toCamelCase"

export default (
  variables,
  dataLayerName = process.env.REACT_APP_DATA_LAYER || "dataLayer"
) => {

  if ( ! variables.eventAction ) {
    console.error( "gtmEvent missing required prop eventAction, sent ", variables )
    return false
  }

  const eventName = process.env.REACT_APP_NAMESPACE
    + ( variables.eventCategory && "." + toCamelCase( variables.eventCategory ) )
    + "." + toCamelCase( variables.eventAction )

  window[dataLayerName] && window[dataLayerName].push({
    'event': eventName,
    ...variables
  })

}
