import React, { useContext } from "react"
import { TrendingUp, DollarSign, Key } from "react-feather"

import Context from "../../context"
import { Header, Button } from "../../components"

export default () => {
  const context = useContext(Context)

  const methodsPerPage = 5

  const selectMethod = (method, nextView) => {
    context.setPaymentMethod(method)
    context.nextStep(nextView)
  }

  const paymentMethods = [
    {
      name: "Bank Withdrawal",
      icon: <DollarSign />,
      notes: "No processing fees!",
    },
    {
      name: "Donor Advised Funds",
      icon: <Key />,
    },
    {
      name: "Stocks",
      icon: <TrendingUp />,
    },
    {
      name: "Check",
      icon: <DollarSign />,
    },
    {
      name: "Wire Transfer",
      icon: <DollarSign />,
    },
  ]

  return (
    <div className="pldf-payment-method pldf-other-ways-to-give">
      <Header title="Other Ways To Give" />
      <div className="pldf-row">

        {paymentMethods
          .slice(0, context.showAllMethods ? paymentMethods.length + 1 : methodsPerPage)
          .map((method, key) => {
            const component = method.component ? method.component : "other-methods"
            return (
              <div className="pldf-col-6" key={key} style={{ order: key >= methodsPerPage ? 3 : 1 }}>
                <Button
                  className={`pldf-payment-method-btn pldf-payment-method-btn-${method.name.toLowerCase().replace(" ", "-")}`}
                  variant="light"
                  type="button"
                  onClick={() => selectMethod(method.name, component)}>
                  <span>
                    <span className="name">{method.name}</span>
                    {method.notes && <span className="pldf-note">{method.notes}</span>}
                  </span>
                </Button>
              </div>
            )
          })}

        {paymentMethods.length > methodsPerPage && (
          <div className="pldf-col-6" style={{ order: 2 }}>
            <Button
              className="pldf-payment-method-btn pldf-payment-method-btn-more"
              type="button"
              variant="secondary"
              onClick={() => context.setShowAllMethods(!context.showAllMethods)}>
              <span>{context.showAllMethods ? "Less" : "More"}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
